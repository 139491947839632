.share{

}

.share__title {
	font-family: $face-brand;
	color: color(brand);
}

.rrssb-buttons li {
	a {
		font-family: $face-brand;
		font-weight: 400;
		background-color: transparent !important;
		box-shadow: inset 0 0 0 1px color(subtle);

		.rrssb-icon svg path{
			fill: #ababab;
			transition: fill .2s ease-in-out;
		}

		.rrssb-text{
			color: color(text);
			transition: color .2s ease-in-out;
		}
	}

	&:hover a{
		box-shadow: none;

		.rrssb-icon svg path{
			fill: color(blank);
		}

		.rrssb-text{
			color: color(blank);
		}
	}

	&.rrssb-linkedin a:hover{
		background-color: #007bb6 !important;
	}
	&.rrssb-facebook a:hover{
		background-color: #306199 !important;
	}
	&.rrssb-twitter a:hover{
		background-color: #26c4f1 !important;
	}
	&.rrssb-pinterest a:hover{
		background-color: #b81621 !important;
	}
	&.rrssb-whatsapp a:hover{
		background-color: rgb(81,170,58) !important;
	}
}

@include media-query(palm){
	.share__title{
		display: none;
	}
}
