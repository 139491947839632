/*----------------------------------------------------------------------------*\
    $Helpers
\*----------------------------------------------------------------------------*/

.bg--wire{
    background-image: url(../img/background-wire.svg);
    background-color: color(tint);
    background-repeat: no-repeat;
}

.continent {
    @include rem(padding, $space*4);
}

.island {
    @include rem(padding, $space*2);
}

.islet {
    @include rem(padding, $space);
}

.text--overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.layer--top{
    padding-top: $space*3;
}

.layer--smalltop{
    padding-top: $space-half;
}

.position--relative{
    position: relative;
}

.sign--btnplaceholder{
    margin-bottom: $space*3;
}

.link--clean{
    text-decoration: none;
    color: inherit;
}

.inline-middle{
    display: inline-block;
    vertical-align: middle;
    margin-right: $space;
    margin-bottom: $space;
}

.inline-middle--last{
    margin-right: 0;
}
/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */



/*----------------------------------------------------------------------------*\
    $FUNCTIONS
\*----------------------------------------------------------------------------*/

@function z-index($keys) {
    $map: $z-indexes;
    $found-index: null;

    @each $key in $keys {
        @if (type-of($map) == "map") {
            $found-index: index(map-keys($map), $key);
            $map: map-get($map, $key);
        }
        @else {
            $found-index: index($map, $key);
        }
    }

    @return $found-index;
}

@function z($keys...) {
    @return z-index($keys);
}



@include media-query(palm){
    .hidden--palm{
        display: none !important;
    }

    .text--left--palm{
        text-align: left !important;
    }

    .intro-mention{
        padding-top: $space;
    }
}
