.searchbar{
	position: absolute;
	top: 0;
	right: $space*3;	
	background-color: color(tint);
	width: 0%;
	transition: width .3s ease-in-out;
	pointer-events: none;

	&.is--open{
		width: calc(100% - #{$space*3});
		pointer-events: all;
	}

	fieldset{
		margin-bottom: 0;
	}

	.field{
		padding: $space;
	}
}

input.searchbar__input{
	width: 100%;
	font-family: $face-brand;
	@include font-size($epsilon, 1.3);
	background-color: transparent;
	outline: none;
	border: 0;
	border-bottom: 2px solid color(blank);
}

.searchbar__submit{
	position: absolute;
	top: 0;
	right: -$space*3;
	font-size: 0;
	display: block;
	width: $space*3;
	height: $space*3.5;
	background-color: transparent;
	border:0;
	outline:0;
}

@include media-query(palm){
	.searchbar{
		&.is--open{
			width: 235px;
		}
	}
}