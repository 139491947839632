.hamburger {
	width: $space;
	height: 20px;
	width: auto;
	padding-right: 25px;
	position: relative;
	cursor: pointer;
}

.hamburger__text {
    margin-right: 10px;
}

.hamburger__line {
	display: block;
	position: absolute;
	height: 2px;
	width: 24px;
	background: color(brand);
	opacity: 1;
	right: 0;
	top: 0;
	transform: rotate(0deg);
	transition: all .25s ease-in-out;
}


.hamburger .hamburger__line--1 {
	top: 0px;
}

.hamburger .hamburger__line--2,.hamburger .hamburger__line--3 {
	top: 9px;
}

.hamburger .hamburger__line--4 {
	top: 18px;
}

.hamburger.open .hamburger__line--1 {
	top: 9px;
	width: 0%;
	right: 12px;
}

.hamburger.open .hamburger__line--2 {
	transform: rotate(45deg);
}

.hamburger.open .hamburger__line--3 {
	transform: rotate(-45deg);
}

.hamburger.open .hamburger__line--4 {
	top: 9px;
	width: 0%;
	right: 12px;
}

@include media-query(palm){
	.hamburger__text{
		display: none;
	}
}
