.tag-container{
	max-height: $space*2.5;
	overflow: hidden;
	transition: max-height .4s ease-in-out;
	line-height: $space*1.25;

	&.is--open{
		max-height: $space*10
	}
}

.tag__item{
	display: block;
    text-decoration: underline;
    color: color(text);
}

.tag__trigger{
	font-family: $face-brand;
	text-decoration: none;
	display: block;
	padding-left: $space;
	margin-bottom: $space-half;
	background-image: url(../img/arrow-down-link.svg);
	background-position: left center;
	background-size: $space-half $space-half;
	background-repeat: no-repeat;
}