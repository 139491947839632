.carousel__holder{
	position: relative;
}

.carousel__arrow{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: $space*2;
	height: $space*2;
	background-color: #FFF;
	background-repeat: no-repeat;
	background-position: center;
	background-size: $space $space;
	cursor: pointer;
	z-index: 5;
}

.carousel__arrow--prev{
	left: 0;
	background-image: url(../img/arrow-left.svg);
}

.carousel__arrow--next{
	right: 0;
	background-image: url(../img/arrow-right.svg);
}

.carousel__item{
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: $space*12;
}

.carousel__content{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index:1;	
	color: color(blank);
	text-align: center;
	width: 100%;
	padding: $space;
	opacity: 0;
	transition: opacity .3s ease-in-out;

	h1,h2,h3,h4,h5,h6{
		color: color(blank);
	}
}

.carousel__overlay{
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(color(brand), .2);
	z-index: 0;
	opacity: 0;
	transition: opacity .3s ease-in-out;
}

.carousel__item:hover .carousel__content{
	opacity:1;
}


.carousel__item:hover .carousel__overlay{
	opacity:1;
}

.carousel__dots{
	background-color: color(blank);
	text-align: center;
	padding: $space;
}