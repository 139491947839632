.mosaic__item{
	position: relative;
	display: block;
	background-size: cover;
	background-position: center;
	margin-bottom: $space-half;
}

.mosaic__content{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index:1;	
	color: color(blank);
	text-align: center;
	width: 100%;
	padding: $space;
	opacity: 0;
	transition: opacity .3s ease-in-out;

	h1,h2,h3,h4,h5,h6{
		color: color(blank);
	}
}

.mosaic__overlay{
	display: block;
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(color(brand), 0);
	transition: background-color .3s ease-in-out;
}

.mosaic__item:hover .mosaic__overlay{
	background-color: rgba(color(brand), .4);
}

.mosaic__item:hover .mosaic__content{
	opacity:1;
}