.card{

}

.card__meta{
	background-color: color(blank);
	@include rem('padding', $space*2);
}

.card__meta--fixed{
	@include rem('min-height', $space*15.5);
}

.card__meta--flat{
	background-color: color(tint);
	@include rem('padding', $space*2 0 0);
}

.card__title{
	@include font-size($gamma, 1.25);
	margin-bottom: $space-half;
	font-family: $face-brand;
	font-weight: 400;
	color: color(brand);
}

.card__title--fixed{
    height: $space*3.75;
    overflow: hidden;
}

.card__image-link{
	display: block;
	position: relative;
}

.card__image{
	display: block;
	width: 100%;
}

.card__overlay{
	opacity: 0;
	transition: opacity .3s ease-in-out;
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(color(brand),.2);
	background-image: url('../img/go-to-arrow.svg');
	background-size: $space*2 $space*2;
	background-position: center center;
	background-repeat: no-repeat;
}

.card__image-link:hover .card__overlay{
	opacity: 1;
}

.card__overlay-meta{
	opacity: 0;
	transition: opacity .3s ease-in-out;
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(color(brand),.2);

	padding: $space*3 $space-half $space;
	text-align: center;
}

.card__meta-title{
	color: color(blank);
	margin-bottom: 0px;
}

.card__meta-subtitle{
	color: color(highlight);
	margin-bottom: $space-half;
	font-family: $face-base;
	font-weight: 400;
}

.card__image-link:hover .card__overlay-meta{
	opacity: 1;
}

.card--content{
	.card__image-link{
		overflow: hidden;
	}
	.card__image{
		transition: transform .3s ease-in-out;
	}
	.card__image-link:hover .card__image{
		transform: scale(1.1);
	}

	&.is--hover{
		.card__image{
			transform: scale(1.1);
		}

		.card__overlay-meta,
		.card__overlay{
			opacity: 1;
		}
	}
}

@include media-query(lap){

	.card--content{
		.card__inner{
			background-color: color(blank);
			padding: $space;
		}

		.card__image-link{
			display: inline-block;
			width: $space*10;
			height: auto;
			margin-right: $space;
		}


		.card__meta{
			display: inline-block;
			width: calc(100% - #{$space*11.5});
			padding: 0;
			min-height: auto;
			vertical-align: top;
		}
	}

}
