.btn{
	display: inline-block;
	cursor: pointer;
	padding: $space*0.75 $space-half;
	text-decoration: none;
	font-family: $face-brand;
	background-color: color(brand);
	color: #FFF;
	transition: background-color, .3s ease-in-out;

	&:hover{
		background-color: lighten(color(brand), 5%);
	}
}

.btn--brand-sec{
	background-color: color(brand-sec);
	&:hover{
		background-color: lighten(color(brand-sec), 5%);
	}
}

.btn--brand-ter{
	background-color: color(brand-ter);
	&:hover{
		background-color: lighten(color(brand-ter), 5%);
	}
}

.btn--subtle{
	background-color: color(subtle);
	color: color(brand);
	&:hover{
		background-color: lighten(color(subtle), 5%);
	}
}

.btn--full{
	width: 100%;
	text-align: center;
}

.btn--ghost{
	background-color: rgba(255,255,255,0);
	color: #FFF;
	box-shadow: inset 0 0 0 1px rgba(255,255,255, 0.5);

	&:hover{
		background-color: rgba(255,255,255, 0.5);
	}
}

.button-row{
	.btn{
		margin-right: $space;
	}

	.btn:last-child{
		margin-right: 0;
	}
}

@include media-query(palm){
	.button-row{
		.btn{
			margin-right: 0;
			display: block;
			width: 100%;
			margin-bottom: $space;
		}
	}
}
