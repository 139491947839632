.splitview{
	font-size: 0;
}

.splitview__half{	
	position: relative;
	display: inline-block;
	width: 50%;
	height: $space*25;
	@include font-size($base-font-size);
	background-size: cover;
	background-position: center center;
}

.splitview__content{
	position: absolute;
	bottom: -$space;
	right: $space*3;
	width: $space*16;
	padding: $space*3.5 $space*1.5 $space*2;
	color: #FFF;

    .splitview__logo {
        position: absolute;
        top: -$space*3;
        left: 0;
        width: 140px;
    }
}

.splitview__content.bg--brand-sec{
	box-shadow: 3px 3px 0px darken(color(brand-sec), 10%);
}

.splitview__content.bg--brand-ter{
	box-shadow: 3px 3px 0px darken(color(brand-ter), 10%);
}

@include media-query(lap){
	.splitview__half{	
		width: 100%;
		height: auto;
		padding: $space*2;
	}

	.splitview__content{
		position: inherit;
		bottom: inherit;
		right: inherit;
		width: 100%;

        margin-top: $space *3;
	}
}

@include media-query(palm){
	.splitview__half{	
		width: 100%;
		height: auto;
		padding: $space*2;
	}

	.splitview__content{
		position: inherit;
		bottom: inherit;
		right: inherit;
		width: 100%;

        margin-top: $space *3;
	}
}

.splitview__smaller {
    .splitview__half {
        height: 300px;
    }

    .splitview__content {
        padding: $space;
        width: auto;
        bottom: $space;

        @include media-query(lap) {
            position: absolute;
            width: auto;
            right: $space*3;
        }

        @include media-query(palm) {
            position: absolute;
            width: auto;
            right: 0;
            bottom: 0;
        }
    }

    .splitview__logo {
        left: - calc(140px + $space);
        top: 50%;
        margin-top: -23px;
    }
}