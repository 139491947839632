/*----------------------------------------------------------------------------*\
    $TOPLEVEL
    html, body, .wrapper
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    /* commented backslash hack \*/
    html, body { height: 100%; }
    /* end hack */
    * html .wrapper { height: 100%; }

    body{
        position: relative;
        min-height: 100%;
        -webkit-font-smoothing: antialiased;
        color: color(text);
    }

    /**
     * Make wrapper 100% height
     */
    .wrapper {
        position: relative;
        min-height: 100%;
        height: auto;
        width: 100%;
        margin: 0 auto;
    }

        .site-width{
             margin: 0 auto;
             @include rem(max-width, $site-width);
             @include rem(padding, 0 $space);
         }

         .site-width--large{
             @include rem(max-width, 1370px);
         }

    hr{
        &.rule{
            height: 1px;
            border: 0px;
            outline: none;
            background-color: color(subtle);
            margin: $space 0px;
            transition: background-color .3s ease-in-out;
        }
    }

    hr.rule--small{
        margin: $space-half/2 0px;
    }

    hr.rule--flat{
        margin: 0;
    }


    @include media-query(palm){
        p img[height],
        p img[width]{
            max-width: 100%;
            height: auto;
        }

        iframe{
            max-width: 100% !important;
            height: auto;
        }
    }

    /*----------------------------------------------------------------------------*\
        Responsive
    \*----------------------------------------------------------------------------*/

    @include media-query(palm){
        .site-width--mobile{
            padding: 0;
        }
    }
