/*----------------------------------------------------------------------------*\
    $BLOCK
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */



/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    .block{}

    .menu-block{
        position: relative;
        display: block;
        text-decoration: none;
        color: color(text);
        text-align: center;
        padding-top: $space;
        background-color: color(blank);
        transition: background-color .3s ease-in-out, box-shadow .3s ease-in-out;
    }

    .menu-block__content{
        padding: $space-half;
    }

    .menu-block__icon{
        margin-bottom: $space;
        fill: #777777;
        transition: fill .3s ease-in-out;
        width: 100px;
        height: 100px;
    }

    .menu-block__title{
        font-family: $face-brand;
        @include font-size($gamma);
        color: color(brand);
        margin-bottom: $space-half;
    }

    .menu-block:hover{
        z-index: 1;
        background-color: color(brand-sec);
        box-shadow: 3px 3px 0px darken(color(brand-sec), 10%);

        .menu-block__title, .menu-block__subtitle {
            color: color(blank)!important;
        }

        .menu-block__icon{
            fill: color(blank)!important;
        }

        &.orange {
            background-color: color(brand-sec);
            box-shadow: 3px 3px 0px darken(color(brand-sec), 10%);
            color: color(blank);
        }

        &.project {
            background-color: color(projects);
            box-shadow: 3px 3px 0px darken(color(projects), 10%);
            color: color(blank);
        }

        &.product {
            background-color: color(products);
            box-shadow: 3px 3px 0px darken(color(products), 10%);
            color: color(blank);
        }

        .menu-block__icon{
            fill: color(brand);
        }

        hr.rule{
            background-color: color(blank);
        }
    }

    .downloads{
        position: relative;
        top: -$space-half;
        padding: $space*4.5 $space*1.25;
        background-color: color(highlight);
        box-shadow: 3px 3px 0px darken(color(highlight), 10%);

        &.coloring-project {
            background-color: color(projects);
            box-shadow: 3px 3px 0px darken(color(projects), 10%);
            * {
                color: color(blank);
            }
        }

        &.coloring-product {
            background-color: color(products);
            box-shadow: 3px 3px 0px darken(color(products), 10%);
            * {
                color: color(blank);
            }
        }
    }

    .sidebar{
        position: relative;
        top: -$space-half;
        padding: $space*4.5 $space*1.25;
        background-color: color(brand-sec);
        color: #FFF;
        box-shadow: 3px 3px 0px darken(color(brand-sec), 10%);
    }

    .sidebar__heading{
        color: color(blank);
        margin-bottom: $space;
    }

    .sidebar__title{
        height: $space*2.75;
        overflow: hidden;
        color: color(blank);
    }

    .fullimage{
        position: relative;
        img{
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .fullimage__inner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: color(blank);
        text-align: center;
        z-index: 1;

        h1,h2,h3,h4{
            color: color(blank);
        }
    }

    .fullimage__overlay{
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background-color: rgba(color(brand), .2);
        pointer-events: none;
        z-index: 0;
    }


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
           .menu-block{
                text-align: left;
            }

            .menu-block__icon{
                display: inline-block;
                vertical-align: top;
                width: 70px;
                height: 70px;
                margin-bottom: 0;
            }

            .menu-block__content{
                vertical-align: top;
                display: inline-block;
                padding: 15px 0;
            }

            .menu-block__title{
                margin-bottom: 0;
            }

            .fullimage__inner{
                position: relative;
                top: inherit;
                left: inherit;
                transform: none;
                color: color(text);
                text-align: left;
                padding: $space;

                h1,h2,h3,h4{
                    color: color(brand);
                }
            }

            .fullimage__overlay{
                display: none;
            }
        }





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/