.filter{
	position: relative;
	height: $space*3;
	z-index: 5;
}

.filter__box{
	padding: $space;
	background-color: #FFF;
	box-shadow: inset 0 0 0 1px color(subtle);
	transition: box-shadow .2s ease-in-out;
	color: color(brand);
	font-family: $face-brand-sec;
	background-image: url(../img/select-arrow.svg);
	background-size: $space-half $space-half;
	background-repeat: no-repeat;
	background-position: right $space top $space*1.25;
}

.filter__label{
	@include font-size($base-font-size);
	font-weight: 500;
}

.filter__options{
	max-height: 0;
	overflow: hidden;
	transition: max-height .3s ease-in-out;
	position: relative;

	.btn{
		display: none;
	}

	&:after{
		content: '';
		display: block;
		background-color: rgba(255,255,255,0);
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		pointer-events: none;
		transition: background-color .3s ease-in-out;
	}

	&.is--loading:after{
		pointer-events: all;
		background-color: rgba(255,255,255,0.8);
	}
}

.filter.is--hover {
	z-index: 6;
	.filter__options{
		max-height: 400px;
	}

	.filter__box{
		box-shadow: inset 0 0 0 2px color(brand);
		background-image: none;
	}
}

.filter__options .field{
	margin: $space-half 0;

	input[type=checkbox]{
		position: relative;
		top: 2px;
		margin-right: $space-half/2;
	}

	label{
		cursor: pointer;
		display: inline-block !important;
		color: color(base);
		font-weight: 400 !important;
		@include font-size($milli);
	}
}

.filter__selection{
	font-family: $face-brand-sec;
}

.filter__selected{
	list-style: none;
	margin: 0;
	padding: 0;
	display: block;
	position: relative;
	left: -$space-half/2;

	li{
		display: inline-block;		
		margin: 0 $space-half/2 $space-half/2;
		
		a{
			display: block;
		}
	}
}

.filter__tag{
	background-color: rgba(color(blank), .5);
	color: color(base);
	padding: $space-half/2 $space-half;
	text-decoration: none;
	transition: background-color .3s ease-in-out;

	span{
		margin-left: $space-half;
		color: color(text);
	}
}

.filter__tag:hover{
	background-color: color(subtle);
}

@include media-query(palm){

}

@include media-query(lap){

}