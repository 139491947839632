.links{
	list-style: none;
	padding: 0;
	margin: 0;	

	&.sign{
		margin-bottom: $space;
	}
}

.links--flat{
	border-bottom: 1px solid #c55f30;
	&:last-child{
		border-bottom: none;
	}	
}


.links--flat li{
	border-bottom: 1px solid #c55f30;
	&:last-child{
		border-bottom: none;
	}	
}

.links__item{
	font-family: $face-brand;
	position: relative;
	display: block;
	box-shadow: inset 0 0 0 1px rgba(255,255,255,0.9);
	color: rgba(255,255,255,0.9);
	padding: $space*0.75 $space-half $space*0.75 $space*3;
	text-decoration: none;
	margin-bottom: $space-half/2;
	background-color: transparent;
	transition: background-color .3s ease-in-out, color .3s ease-in-out, box-shadow .3s ease-in-out;	
}

.links__item--flat{
	box-shadow: none;
	color: color(blank);	
	padding: $space*0.75 $space-half;
	margin-bottom: 0;
	opacity: 0.7;
	transition: background-color .3s ease-in-out, opacity .3s ease-in-out;
}

.links__type{
	position: absolute;
	top: 0; bottom: 0; left: 0;
	width: $space*2.5;
	background-color: transparent;
	transition: background-color .3s ease-in-out;
}

.link__icon{
	fill: color(blank);
	transition: fill .3s ease-in-out;
	position: absolute;
	top: $space*0.75;
	left: $space*0.75;
}

.links__item:hover{
	box-shadow: none;
	background-color: lighten(color(highlight), 15%);
	color: color(brand);
}

.links__item:hover .links__type{
	background-color: color(blank);
}

.links__item:hover .link__icon{
	fill: color(brand);
}

.links__item--flat:hover,
{
	opacity: 1;	
	background-color: #bd5525;
	color: color(blank);
}

.links__item--flat.current{
	opacity: 1;
	background-color: #c55f30;
	color: color(blank);	
}