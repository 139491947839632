@-webkit-keyframes movebg {
	0% {
		opacity: 0;
	 transform: translateX(2.5%) scale(1.06);
	}
	3% {
		opacity: 1;
	}
	97% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	 transform: translateX(-2.5%) scale(1.06);
	}
}
@keyframes movebg {
	0% {
		opacity: 0;
	 transform: translateX(2.5%) scale(1.06);
	}
	3% {
		opacity: 1;
	}
	97% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	 transform: translateX(-2.5%) scale(1.06);
	}
}

.hero-slider{position: relative;}

.hero-slider__slide{
	position: relative;
	height: calc(100vh - #{$space*3.5});
}

.hero-slider__slide-bg{
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background-size: cover;
	background-position: top center;
	transform: translateX(0) scale(1);
}

.anim--movebg{
	-webkit-animation: movebg 7s linear;
	animation: movebg 7s linear;
}

.hero-slider__overlay{
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(color(brand), .2);
	pointer-events: none;
	z-index: 0;
}

.hero-slider__slide-title{
	width: 600px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, $space*7);
	color: #FFF;
	text-align: center;
	z-index: 1;

	.hero-title{
		font-family: $face-brand;
		color: #FFF;
		@include font-size($mega, 1.25);
		font-weight: 400;
		@include rem(margin-bottom, $space);
	}
}

.hero-slider__button-container {
    position: absolute;
    width: 100%;

    bottom: 0;
}

.hero-slider__meta{
	width: 100%;
	position: absolute;
	left: 0;
	bottom: -$space*13;
	pointer-events: none;

	.hero-slider__slider-nav, .hero-slider__slider-content, .hero-slider__dots, a.btn{
		pointer-events: all;
	}
}

.hero-slider__slider-content{
	padding: $space*2 $space*2 $space $space*2;
	background-color: #FFF;
}

.hero-slider__slider-nav{
	display: block;
	font-size: 0;
}

.hero-slider__nav-arrow{
	display: inline-block;
	width: $space*2;
	height: $space*2;
	padding: $space-half;
	cursor: pointer;
	font-size: $base-font-size;
	background-color: rgba(255,255,255, 0.7);
	transition: background-color .3s ease-in-out;

	&:hover{
		background-color: rgba(255,255,255,1)
	}
}

.hero-slider__meta-photos{
    position: relative;
	height: $space*11;
	background-color: color(subtle);
	font-size: 0;
	overflow: hidden;
}

.hero-slider__meta-photo{
	display: inline-block;
	width: 50%;
	height: auto;
}

.hero-slider__dots{
	text-align: center;
	padding: $space 0 0;
}

.hero-slider__slider-content-detail,
.hero-slider__meta-photos-detail{
	display: none;
   

	&.is--active{
        display: block;
    }
}

@include media-query(lap){
	.hero-slider__slide{
		height: 600px;
	}

	.hero-slider__slider-content{
		padding: $space;
	}

	.hero-slider__slide-title{
		width: 95%;
		transform: translate(-50%, $space*3);

		h1{
			@include font-size($beta, 1);
		}
	}

	.hero-slider__meta-photos{
		height: auto;
	}
}

@include media-query(palm){
	.hero-slider__meta{
		position: relative;
		top: -$space*2;

		.site-width{
			padding: 0;
		}
	}

	.hero-slider__slide{
		height: 400px;
	}

	.hero-slider__slider-content{
		padding: $space;
	}

	.hero-slider__slide-title{
		width: 95%;
		transform: translate(-50%, $space*3);

		h1{
			@include font-size($beta, 1);
		}
	}

	.hero-slider__meta-photos{
		height: auto;
	}
}
