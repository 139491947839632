/*----------------------------------------------------------------------------*\
    $HEADER
    top of page
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    .header{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: $space*3.5;
        background: #FFF;
        z-index: 9999;
        will-change: transform;
        transition: transform .3s ease-in-out;
        box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
    }

    .header--up{
        transform: translateY(-100%);
    }

    .wrapper{
        padding-top: $space*3.5;
    }

    .header__links{
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 0;

        li{
            @include font-size($base-font-size);
            display: inline-block;
            vertical-align: top;
            border-right: 1px solid color(subtle);

            &:first-child{border-left: 1px solid color(subtle);}
        }
    }

    .header__link{
        display: block;
        padding: $space*1.25 $space;
        font-family: $face-brand-sec;
        text-transform: uppercase;
        color: color(brand);
        text-decoration: none;

        .svg--icon{
            display: block;
            float: left; // vage margin bug
        }

        overflow: hidden;
    }

    .header__link--mobile{
        padding: 25.25px $space;
    }

    .header__link-text{
        padding-left: $space*2;
        display: none;
        line-height: 20px;

        &.js--active{
            display: block;
        }
    }



    .header__logo img{
        max-width: 100%;
    }

/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        @include media-query(palm){
            .header__logo{
                padding-left: $space;
            }

            .header__link{
                padding: $space*1.25 $space/2;
            }

            .header__link--mobile{
                padding: 25.25px $space/2;
            }
        }





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
