.gallery__holder{
	position: relative;
}

.gallery__arrow{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: $space*2;
	height: $space*2;
	background-color: #FFF;
	background-repeat: no-repeat;
	background-position: center;
	background-size: $space $space;
	cursor: pointer;
}

.gallery__arrow--prev{
	left: $space*3.5;
	background-image: url(../img/arrow-left.svg);
}

.gallery__arrow--next{
	right: $space*3.5;
	background-image: url(../img/arrow-right.svg);
}

.gallery__item{
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 30vw;
	max-height: $space*30;

	img{
		position: absolute;
		width: 100%;
		transform: translateY(-50%);
		top: 50%;
        object-fit: cover;
	}
}

.gallery__overlay{
	position: absolute;
	top: 0; right: 0; bottom: 0; left: 0;
	background-color: rgba(color(brand), .8);
	z-index: 10;
	opacity: 1;
	transition: background-color .5s ease-in-out;
}

.slick-current .gallery__overlay{background-color: rgba(color(brand), 0);}

.gallery__content{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	z-index:1;
	color: color(blank);
	max-width: $space*30;
	opacity: 0;
	transition: opacity .2s ease-in-out;

	&.is--open{
		opacity: 1;
	}

	h1,h2,h3,h4,h5,h6{
		color: color(blank);
	}
}

.gallery__dots{
	text-align: center;
	padding: $space;
}

@include media-query(lap){
	.gallery__item{
		height: auto;

		img{
			position: static;
			transform: none;
		}
	}

	.gallery__arrow--prev{
		left: $space;
	}

	.gallery__arrow--next{
		right: $space;
	}
}

@include media-query(palm){
	.gallery__item{
		height: auto;

		img{
			position: static;
			transform: none;
		}
	}

	.gallery__arrow{
		top: inherit;
		bottom: -$space*3.5;
	}

	.gallery__arrow--prev{
		left: 0;
	}

	.gallery__arrow--next{
		right: 0;
	}

	.gallery__content{
		width: 95%;
		text-align: center;
		.mega{@include font-size($beta);}
	}

}
