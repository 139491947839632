/*----------------------------------------------------------------------------*\
    $LAYER
    page part
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/

    .layer{
        padding: $space*4 0;
    }

    .layer--wide{
        padding: $space*6 0;
    }

    .layer--small{
        padding: $space*2 0;
    }




/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/