/*----------------------------------------------------------------------------*\
    $FONT
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

    /*
    CONTENTS................You're reading it, LOL
    MODULE..................Includes base module & submodules
    STATE...................States the module can be in
    THEME...................Theming of the module
    */





/*----------------------------------------------------------------------------*\
    $MODULE
\*----------------------------------------------------------------------------*/


h1,
h2,
h3,
h4,
h5,
h6 {

}

h1,
h2{
    font-family: $face-brand;
    @include font-size($beta, 0.75);
    font-weight: 400;
    color: color(brand);
    @include rem(margin-bottom, $space);
}

h3{
    font-family: $face-brand-sec;
    font-weight: 700;
    color: color(brand);
    @include font-size($epsilon);
}

.heading--subtitle{
    text-transform: none;
    margin-bottom: 0;
    @include font-size($epsilon);
    font-weight: 500;
}

h4,
h5,
h6{
    font-family: $face-brand-sec;
    font-weight: 700;
}

strong{
    color: #5f5f5f;
}

a{
    color: color(brand-light);
    &.hide{
        text-decoration: none;
    }
}

.subtitle{
    font-family: $face-brand-sec;
    color: color(brand-sec);
    font-weight: 600;
    text-transform: uppercase;
}

.subtitle-sec{
    color: color(brand-light);
    font-family: $face-base;
    @include rem(margin-bottom, $space-half);
}

.date{
    font-family: $face-brand-sec;
    color: color(brand-sec);
    font-weight: 600;
    text-transform: uppercase;
}

.sidebar .date{
    color: rgba(255,255,255,0.35);
}

.date--title{
    color: color(text);
    font-style: italic;
    font-weight: 400;
    text-transform: none;
    @include font-size($milli);
}

address{
    font-style: normal;
}

.gamma{
    line-height: $space*1.25;
}

.intro{
    padding: $space-half 0 $space-half $space;
    border-left: 2px solid color(brand-sec);

    font-family: $face-brand-sec;
    color: color(brand-sec);
    font-size: $delta;
    margin-bottom: $space-half;

    p:last-child,
    ul:last-child{
        margin-bottom: 0;
    }
}

.title-l{
    font-family: $face-brand;
    @include font-size($kilo);
    font-weight: 400;
    color: color(brand);
    @include rem(margin-bottom, $space);
}

.title-m{
    font-family: $face-brand;
    @include font-size($beta, 0.75);
    font-weight: 400;
    color: color(brand);
    @include rem(margin-bottom, $space);
}

.title-s{
    font-family: $face-brand-sec;
    font-weight: 700;
    color: color(brand);
    @include font-size($epsilon);
    @include rem(margin-bottom, $space/2);
}

.is--cmscontent{
    h2{
        font-family: $face-brand-sec;
        font-weight: 700;
        color: color(brand);
        @include font-size($epsilon);
        @include rem(margin-bottom, $space/2);
    }
}


/*----------------------------------------------------------------------------*\
    $STATE
\*----------------------------------------------------------------------------*/

    /* Media Queries
       The default media queries shipped with Blocss:
       Uncomment the ones you need
    \*------------------------------------------------------------------------*/

        /**
         * Desk-wide state
         */
        //@include media-query(gt-desk){}


        /**
         * Desk state
         */
        //@include media-query(desk){}


        /**
         * Portable state
         */
        //@include media-query(lt-desk){}


        /**
         * Lap state
         */
        //@include media-query(lap){}


        /**
         * Lapup state
         */
        //@include media-query(gt-palm){}


        /**
         * Palm state
         */
        //@include media-query(palm){}





/*----------------------------------------------------------------------------*\
    $THEME
\*----------------------------------------------------------------------------*/
