form:not(#SearchForm_zoeken){
	font-size: 0;

	.field,
	.btn-toolbar{
		@include font-size($base-font-size);
	}

	.field{
		margin-bottom: $space-half;

		&.half{
			display: inline-block;
			width: calc(50% - #{$space-half});

			&.last{
				margin-left: $space;
			}
		}

		label{
			display: block;
			font-family: $face-brand-sec;
			color: color(brand);
			font-weight: 500;

			span{
				color: #ea3434;
				font-weight: 700;
			}
		}

		textarea{
			padding: $space-half;
			border: 1px solid color(subtle);
			width: 100%;
			resize: vertical;
			height: $space*5.5;
		}

		&.text{
			input{
				padding: $space-half;
				border: 1px solid color(subtle);
				width: 100%;
			}
		}
	}

	.btn-toolbar{
		input{
			border: none;
		}
	}

	.form__smalltext{
		font-size: 12px;
		margin-bottom: 0;
	}
}

form.form--cta{

	fieldset{
		display: inline-block;
		position: relative;
	}

	.field.text{
		display: inline-block;

		input{
			min-width: $space*15 !important;
			width: auto !important;
			background-color: transparent;
			outline: none;
			border: none !important;
			border-bottom: 2px solid #FFF !important;
			text-align: right;
			color: #FFF;
			margin-right: $space;
			padding: $space-half 0 !important;
			@include font-size($gamma);
			opacity: .5;
			transition: opacity .3s ease-in-out;


			&::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			    color:    #FFF;
			}
			&:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			   color:    #FFF;
			   opacity:  1;
			}
			&::-moz-placeholder { /* Mozilla Firefox 19+ */
			   color:    #FFF;
			   opacity:  1;
			}
			&:-ms-input-placeholder { /* Internet Explorer 10-11 */
			   color:    #FFF;
			}
			&::-ms-input-placeholder { /* Microsoft Edge */
			   color:    #FFF;
			}

			&:focus,
			&.has--value{
				opacity: 1;
			}
		}

	}

	.btn-toolbar{
		display: inline-block;
	}

	input[type=submit]{
		width: $space*2.5;
		height: $space*2.5;
		background-color: #FFF;
		border: none;
		outline: none;
		font-size: 0;
		background-image: url(../img/arrow-right.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: $space $space;
	}

	.form--cta__smalltext{
		color: #b291b4;
		font-size: 12px;
		position: absolute;
		width: 100%;
		right: 0;
		text-align: left;

		a {
			color: color(blank);
		}
	}
}

.form--cta--vacancy{
	fieldset{
		max-width: 680px;
	}
	#VacancyApplyForm_CV_Holder{
		display: inline-block;
		label{
			margin-bottom: $space/2;
			color: color(blank) !important;
			text-align: right;
			font-family: $face-base !important;
			opacity: .5;
			position: relative;
			top: 5px;
		}
	}
	input[type="file"]{
		@extend .btn;
		color: color(blank) !important;
		background-color: darken(color(brand-ter), 3%) !important;
		// border: 1px solid #b390b5;
	}
	.btn-toolbar{
		position: relative;
		bottom: 5px;
	}
}

// MEDIA QUERIES

@include media-query(palm){
	form,
	form:not(#SearchForm_zoeken){
		.field{
			&.half{
				display: block;
				width: 100%;

				&.last{
					margin-left: 0
				}
			}
		}
	}

	form.form--cta{

		fieldset{
			display: block;
		}

		.field.text{
			display: block;

			input{
				min-width: auto !important;
				text-align: left;
				margin-right: 0;
			}

		}

		.btn-toolbar{
			display: block;
		}

		input[type=submit]{
			@include font-size($base-font-size);
			background-position: center right $space-half;
			padding: 0 $space*3 0 $space;
			font-family: $face-brand;
			color: color(brand);
			font-weight: 500;
			width: auto;
		}

		.form--cta__smalltext{
			position: static;
			width: 250px;
			margin: 0 auto;
		}
	}

	.form--cta--vacancy{
		input[type="file"]{
			max-width: 250px;
		}
		.btn-toolbar{
			bottom: 0px;
		}
	}
}

@include media-query(lap){
	form.form--cta{
		.form--cta__smalltext{
			position: static;
			width: 60%;
			margin: 0 auto;
		}
	}

	.form--cta--vacancy{
		.btn-toolbar{
			bottom: 0px;
		}
	}

}
