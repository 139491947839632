.avatar{
    position: relative;
	border-radius: 50%;
	width: 120px;
	height: 120px;
	overflow: hidden;
	display: inline-block;

	img{
		position: absolute;
		left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
	}
}