/*----------------------------------------------------------------------------*\
    $COLOR
\*----------------------------------------------------------------------------*/

/* Color function
   Using colors in css example:
   background-color: color(brand);
\*------------------------------------------------------------------------*/
@function color($key) {
    @if map-has-key($colors, $key) {
        @return map-get($colors, $key);
    }

    @error "Unknown `#{$key}` in $colors.";
    @return null;
}

/**
 * Backgrounds & colors
 */
@each $color, $value in $colors{
    .bg--#{$color} {
        background-color: $value !important;
    }
    .color--#{$color} {
        color: $value !important;
    }
    .fill--#{$color} {
        fill: $value !important;
    }
}