.hero{
	height: $space*18;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

@include media-query(lap){
	.hero{
		height: $space*10;
	}	
}

@include media-query(palm){
	.hero{
		height: $space*8;
	}	
}