.menu-dropdown{
	position: fixed;
	top: 0; left: 0;
	width: 100%;
	padding: $space*5.5 0 $space*2;
	background-color: color(blank);
	z-index: 9998;
	transform: translateY(-100%);
    transition: transform .3s ease-in-out;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
}

.menu-dropdown--down{
	transform: translateY(0);
}

.dropdown-submenu ul{
	list-style: none;
	margin: 0;
	padding: 0;
}

.dropdown-submenu ul li{
	border-bottom: 1px solid color(subtle);

	&:last-child{
		border-bottom: 0px;
	}
}

.dropdown-submenu ul li a{
	display: block;
	padding-left: $space-half;
	font-family: $face-brand;
    @include font-size($delta, 2);
    color: color(brand);
    text-decoration: none;
    transition: color .3s ease-in-out;
}

.dropdown-submenu ul li.current a,
.dropdown-submenu ul li.section a,
.dropdown-submenu ul li a:hover{
	color: color(brand-light);
}

@include media-query(palm){
	.menu-dropdown{
		height: 95%;
		overflow: auto;
		padding: $space*3 0 $space*2;
	}
}